<template>
  <div style="height:100%">
    <div class="google-map" ref="googleMap" ></div>
    <template v-if="!!this.google && !!this.map">
      <slot
        :google="google"
        :map="map"
      />
    </template>  
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
//import { Loader } from '@googlemaps/js-api-loader'
export default {
  props: {
    mapConfig: Object,
    apiKey: String,
    setGoogleMap: Function
  },

  data() {
    return {
      google: null,
      map: null
    }
  },

  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      libraries: ['visualization'],
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    this.initializeMap()
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(
        mapContainer, this.mapConfig
      )
      this.setGoogleMap(this.google, this.map)
    },
  }
}
</script>
<style scoped>
.google-map {
  position: relative;  
  padding-top:55.0%;
  min-width: 98%;
  min-height: 400px;
  height: 100%;
  border: 1px solid gray;  
}
</style>