<template></template>
<script>
import colorPalette from '@/constants/colorPalette'
import { LINE_PATH_CONFIG } from '@/constants/mapSettings'
const { COLOR_BROWN, COLOR_BLACK, COLOR_BLUE, COLOR_LIGHT_BLUE, COLOR_WHITE, COLOR_WHITEY, COLOR_GREEN, COLOR_TOMATO } = colorPalette

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    path: {
      type: Array,
      required: true
    },
    googleMapLines: Array,
  },
  data () {
    return {
      line: null
    }
  },
  mounted () {
    const { Polyline } = this.google.maps
    this.line = new Polyline({
      clickable: false,
      path: this.path,
      map: this.map,
      ...LINE_PATH_CONFIG
    })
    this.line.strokeColor = COLOR_BLUE
    this.googleMapLines.push(this.line)
  }
}
</script>